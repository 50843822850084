import React, { useEffect, useState } from "react";
import {
  Container,
  PageContainer,
  HeaderTitle,
  TabsContainer,
  NavContainer,
  Wrapper,
} from "./styles";
import Page from "../../components/page/index";
import APIKeys from "./components/apiKeysTable/index";
import Tabs from "../../components/Tabs";
import DocGrey from "../../assets/DocGrey.svg";
import Key from "../../assets/Key.svg";
import LoginWrapper from "../../store/LoginWrapper";
import Overview from "./components/Overview/index.js";
import Home from "../../assets/NavIcons/HomeIcon.svg";
import { useGlobalData } from "../../store/GlobalDataProvider.js";
import { SquareButton } from "../../components/buttons/SquareButton.js";
import RequestAccessModal from "./components/requestAccessModal/index.js";
import NavBar from "../../components/navbar/NavBar.js";

const API = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { userData } = useGlobalData();
  const [open, setOpen] = useState(false);
  const [apiEnabled, setApiEnabled] = useState(false);

  useEffect(() => {
    if (userData) {
        setApiEnabled(userData.payments.api.enabled || userData.payments.api.freeBills > 0);
    }
  }, [userData]);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setTabInParent = (index) => {
    setActiveTab(index);
  };

  const handleDocsClick = () => {
    window.open("/apiDocumentation", "_blank");
  };

  const handleWebhookClick = () => {
    setActiveTab(1);
  };

  return (
    <LoginWrapper>
      <Page currentPage={'api'}>
        <Container width={"900px"}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <HeaderTitle>API</HeaderTitle>
              <TabsContainer>
                <Tabs
                  tabs={["Overview", "Keys"]}
                  setTabInParent={setTabInParent}
                />
              </TabsContainer>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{ display: apiEnabled ? "none" : "" }}
                onClick={handleOpenModal}
              >
                <SquareButton text="Get Access" width="100px" />
              </div>
              <div style={{ marginLeft: 8 }} onClick={handleDocsClick}>
                <SquareButton text="View Documentation" width="160px" />
              </div>
            </div>
          </div>
          {activeTab === 0 && (
            <Overview
              handleDocsClick={handleDocsClick}
              handleWebhookClick={handleWebhookClick}
            />
          )}
          {activeTab === 1 && (
            <APIKeys apiEnabled={apiEnabled} setOpen={handleOpenModal} />
          )}
        </Container>

        <RequestAccessModal open={open} handleClose={handleClose} />
      </Page>
    </LoginWrapper>
  );
};

export default API;
