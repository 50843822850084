import styled from "styled-components";
import { blue, dark_blue, light_blue } from "../../utils/colors.js";

export const Container = styled.div`
  font-family: Plus Jakarta Sans;
`;

export const SuccessContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  align-items: center;
  margin-top: 100px;
`;

export const SuccessIcon = styled.img`
  width: 60px;
  height: 60px;
`;

export const SuccessHeader = styled.div`
  font-size: 30px;
  font-weight: 500;
`;

export const SuccessSubheader = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
`;

export const CTA = styled.div`
  cursor: pointer;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: ${blue};
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 20px;
  transition: 0.3s;

  &:hover {
    background-color: ${dark_blue};
  }
`;
