import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import Logo from "../../../assets/BlackLogo.svg"
import UpgradeImage from "../../../assets/UpgradeAccount.png"
import { SquareButton } from "../../../components//buttons/SquareButton";
import { contactSales } from "../apiCalls/contactSales";
import { useSnackbar } from "notistack";
import { buttons, snackbarStyles } from "../../../components/snackbars/MessageBar";
import { useAuth } from "../../../store/AuthContext";
import { useNavigate } from "react-router-dom";

const StyledBox = styled.div`
  width: 560px;
  font-family: Plus Jakarta Sans;

  & .group {
    left: 0;
    top: 0;
    width: 560px;

    & .text-wrapper {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      left: 105px;
      letter-spacing: 0;
      line-height: 39px;
      text-align: center;
      width: 560px;
      margin-bottom: 0px;
    }

    & .image {
      height: 305px;
      object-fit: cover;
      width: 458px;
      margin-left: 51px;
    }

    & .logo {
      height: 15px;
      left: 219px;
      top: -1px;
      width: 110px;
      margin-left: 225px;
    }

    & .congratulations-on {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      left: 0;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      width: 547px;
      margin-top: 0px;
      margin-bottom: 26px;
    }

    & .buttonContainer {
        padding-left: 200px;
        margin-bottom: 30px;
    }

    & .overlap-group {
      background-color: #f3f6ff;
      border: 1px solid;
      border-color: #3958ff;
      border-radius: 5px;
      height: 31px;
      left: 198px;
      width: 152px;

      & .div {
        color: #3958ff;
        font-size: 12px;
        font-weight: 500;
        left: 35px;
        letter-spacing: 0;
        line-height: normal;
        top: 7px;
      }
    
    }
  }
`;

const Image = styled.img`
  width: 100%;
`

const UpgradeAccountModal = ({ open, handleClose, upload }) => {
    
    const navigate = useNavigate();
 
    const modalContent = () => {
        return (
            <StyledBox>
            <div className="group">
                <img className="logo" alt="Logo" src={Logo} />
                    <div>
                        <p className="text-wrapper">Monthly Usage Limit Reached!</p>
                        <Image alt="upgradeImage" src={UpgradeImage} />
                        <p className="congratulations-on">{upload ? "The number of files being uploaded exceeds your remaining utility bills for this month. To continue using the Upward platform, head to the account page and upgrade your subscription." : "You have reached your monthly usage limit! To continue using the Upward platform, head to the account page and upgrade your subscription."}</p>
                        <div 
                        onClick={() => navigate("/account")}
                        className="buttonContainer"
                        >
                            <SquareButton text='Upgrade Now'/>
                        </div>
                    </div>
            </div>
        </StyledBox>
        );
    };

    return (
        <Modal
        modalContent={modalContent()}
        open={open}
        handleClose={handleClose}
        />
    );
};

export default UpgradeAccountModal;
