import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export default async function getStripeCheckoutUrl( currentUser, product, period) {
  try {
    
    const idtoken = typeof currentUser === 'string' ? currentUser : await currentUser.getIdToken();

    // Make a POST request to the endpoint
    const res = await axios(`${expressHost}/payments/createCheckoutSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        idtoken: idtoken,
      },
      data: { product: product, period: period },
    });
    window.location.href = res.data.url;
  } catch (error) {
    console.error("Error during checkout:", error);
  }
};