import styled from "styled-components";

export const NavContainer = styled.div`
  display: flex;
  width: 100vw;
  min-width: 1100px;
`;

export const PageContainer = styled.div`s
  background-color: #fdfdfd;
  padding: 48px 36px 0 36px;
  width: 100%;
`;
