import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlackLogo from "../../assets/BlackLogo.svg";
import BackArrow from "../../assets/BackArrow.svg";
import Box from "@mui/material/Box";
import Login from "./components/Login";
import Signup from "./components/Signup";
import { useLocation, useNavigate } from "react-router-dom";
import { dark_blue } from "../../utils/colors";
import { useAuth } from "../../store/AuthContext";
import "@fontsource/plus-jakarta-sans";

const Container = styled.div`
  background-color: #4c52e7;
  height: 100vh;
  font-family: "Plus Jakarta Sans";
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: max-content;
  position: relative;
  margin: auto;
  height: 100%;
  justify-content: center;
`;

const BackButtonContainer = styled.div`
  width: 20px;
  margin-bottom: 30px;
`;

const BackArrowImg = styled.img`
  height: 20px;
  width: 20px;
  margin-top: 1px;
  &:hover {
    animation-name: bounce;
    animation-timing-function: ease;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const BackButtonText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: -1px;
`;

const BackButton = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;

  ${BackButtonText} {
    color: white;
  }

  ${BackArrowImg} {
    filter: brightness(0) invert(1);
  }
`;

const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  width: 360,
  height: "max-content",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const Img = styled.img`
  &:hover {
    fill: white;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 60px;
`;

const SignUpLoginText = styled.div`
  font-size: 12px;
`;

const SignUpLoginSpan = styled.span`
  color: #3958ff;
  cursor: pointer;
  font-weight: 500;
  transition: 0.03s;
  &:hover {
    color: ${dark_blue};
    text-decoration: underline;
  }
`;

function Auth() {
  const location = useLocation();
  const [loginState, setLoginState] = useState(
    location.pathname.includes("login")
  );
  const [fromPricing, setFromPricing] = useState(false);
  const [product, setProduct] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== null) {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    var url = new URLSearchParams(window.location.search);
    if (url.has("pricing") && url.has("product")) {
      if (url.get("pricing") === "true") {
        setFromPricing(true);
        setProduct(url.get("product"));
      }
    }
  }, []);

  return (
    <Container>
      <FormContainer>
        {!location.pathname.includes("utilityApi") && (
          <BackButtonContainer
            onClick={() => window.location.assign("https://buildupward.com")}
          >
            <BackButton>
              <BackArrowImg src={BackArrow} />
              <BackButtonText>Back</BackButtonText>
            </BackButton>
          </BackButtonContainer>
        )}

        <Box sx={style}>
          <LogoContainer>
            <Img src={BlackLogo} height={"30px"} />
          </LogoContainer>
          <SignUpLoginText>
            {loginState ? (
              <></>
            ) : (
              <div>
                Already have an Account?{" "}
                <SignUpLoginSpan
                  onClick={() => {
                    setLoginState(true);
                  }}
                >
                  Login.
                </SignUpLoginSpan>
              </div>
            )}
          </SignUpLoginText>
          {loginState ? (
            <Login setLoginState={setLoginState} />
          ) : (
            <Signup fromPricing={fromPricing} product={product} />
          )}
        </Box>
      </FormContainer>
    </Container>
  );
}

export default Auth;
