export const numberToMonth = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const millisecondsToDate = (seconds) => {
  var d = new Date(0);
  d.setUTCSeconds(seconds);
  return `${numberToMonth[d.getMonth()]} ${d.getDate()}`;
};

export function sortDates(dateStrings) {
  // Custom comparator function to compare "Month-Year" strings
  function compareDates(a, b) {
    const [monthA, yearA] = a.split("-").map(Number);
    const [monthB, yearB] = b.split("-").map(Number);

    if (yearA !== yearB) {
      return yearA - yearB; // Compare years first
    } else {
      return monthA - monthB; // If years are equal, compare months
    }
  }

  // Use the custom comparator with the sort function
  return dateStrings.sort(compareDates);
}

export function formatFirebaseDate(firebaseDate) {
  // Convert _seconds to milliseconds (Firebase Timestamp stores time in seconds)
  const milliseconds = firebaseDate._seconds * 1000 + Math.floor(firebaseDate._nanoseconds / 1000000);
  const date = new Date(milliseconds);  // Create JavaScript Date object
  
  const month = (date.getMonth() + 1).toString().padStart(2, '0');  // getMonth() returns 0-11, so add 1
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
}