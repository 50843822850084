import React from "react";
import Page from "../../components/page/index";
import LoginWrapper from "../../store/LoginWrapper";
import Confetti from "react-confetti";
import { blue } from "../../utils/colors.js";
import {
  Container,
  SuccessContainer,
  SuccessIcon,
  SuccessHeader,
  SuccessSubheader,
  CTA,
} from "./styles.js";
import greenCheck from "../../assets/greenCheck.svg";

const PaymentSuccess = ({ handleClose }) => {
  return (
    <Container>
      <Confetti
        height={"430px"}
        width={"460px"}
        colors={[blue, "#00093D", "#E7F1FF"]}
        numberOfPieces={100}
        opacity={0.75}
      />
      <SuccessContainer>
        <SuccessIcon src={greenCheck} />
        <SuccessHeader>Payment Successful</SuccessHeader>
        <SuccessSubheader>
          Welcome to Upward! We look forward to streamlining your work with utility bills. Reach out to us at anytime if you have any questions.
        </SuccessSubheader>
        <CTA onClick={handleClose}>Get Started</CTA>
      </SuccessContainer>
    </Container>
  );
};

export default PaymentSuccess;
