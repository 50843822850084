import styled from "styled-components";
import { blue, light_red, dark_red } from "../../utils/colors";
import "@fontsource/plus-jakarta-sans";

export const PageContainer = styled.div`
  width: Calc(100vw - 220px);
  min-width: 974px;
  background-color: #fdfdfd;
  font-family: "Plus Jakarta Sans";
`;

export const HeaderTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

export const Container = styled.div`
  width: Calc(100vw - 500px);
  margin: 0 auto  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: "Plus Jakarta Sans";
`;

export const AnnualBillingBanner = styled.div`
  width: 100%;
  height: 110px;
  background-color: #eff2ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20%;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BannerTitleIcon = styled.img``;

export const BannerTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const BannerSubtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
`;

export const CardsContainer = styled.div`
  padding: 36px;
  //   height: calc(100vh - 328px);
  display: grid;
  column-gap: 32px;
  row-gap: 32px;
  // flex-grow: 2;
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
`;

export const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
    0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
  padding: 36px;
  max-width: 458px;
  width: 458px;
  min-height: 148px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 5px;
`;

export const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const CTA = styled.div`
  color: ${blue};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export const Regular16 = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const Light36 = styled.div`
  font-size: 36px;
  font-weight: 200;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Medium16 = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const Light16 = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const FlexColGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  gap: 32px;
`;

export const SemiBold16 = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const SemiBold20 = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const Red = styled.div`
  //   color: ${dark_red};
  color: #fa0e0e;
`;

export const RightArrow = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:hover {
    animation-name: bounce;
    animation-timing-function: ease;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const FlexRowContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const PlusIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SeatsLeft = styled.div`
  padding-left: 36px;
  padding-top: 36px;
`;
