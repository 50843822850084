import React, { useState, useEffect } from "react";
import Plus from "../../assets/Plus.svg";
import CircularButton from "../../components/buttons/CircularButton";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/navbar/NavBar";
import Card from "../../components/cards/Card";
import Collaboration from "../../assets/Collaboration.svg";
import { useGlobalData } from "../../store/GlobalDataProvider";
import RefreshButton from "../../components/buttons/RefreshButton";

import DeleteModal from "./components/DeleteModal";
import UpgradeAccountModal from "./components/UpgradeAccount";

import {
  NavContainer,
  Container,
  PageContainer,
  Header,
  HeaderActions,
  HeaderTitle,
  HeaderSubtitle,
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateText,
  EmptyStateTextHeader,
  EmptyStateTextContainer,
} from "./styles";
import DataGrid from "../../components/dataGrid";
import LoginWrapper from "../../store/LoginWrapper";
import LinkButton from "../../components/buttons/linkButton";
import LinkModal from "./components/LinkModal";
import { SquareButton } from "../../components/buttons/SquareButton";
import Page from "../../components/page";
import PaymentSuccessModal from "../../components/modals/PaymentSuccess/index.js";

const DataRequests = () => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteKey, setDeleteKey] = useState("");
  const [deleteStatus, setDeleteStatus] = useState("");
  const [upgradeAccountModalOpen, setUpgradeAccountModalOpen] = useState(false);
  const [needsToUpgrade, setNeedsToUpgrade] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);

  const { userData, loading, refreshUserData } = useGlobalData();
  const [allRowData, setAllRowData] = useState([]);
  const [firstTime, setFirstTime] = useState(false);

  const handleClosePaymentSuccess = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("paymentSuccess", "true");
    setOpenPaymentSuccess(false);
    navigate("/home");
  };

  function extractRows(data) {
    const result = [];
    for (const status in data) {
      for (const account in data[status]) {
        result.push({
          ...data[status][account],
          rowStyle: { cursor: "pointer" },
        });
      }
    }
    return result;
  }

  function getCurrentMonth() {
    var date = new Date();
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    return year + "-" + month;
  }

  function checkMonthlyUsage() {
    if (
      userData &&
      userData.payments.subscription.remainingBills >
        userData.payments.subscription.monthlyUsageLimit
    ) {
      setUpgradeAccountModalOpen(true);
      setNeedsToUpgrade(true);
    }
  }

  useEffect(() => {
    var url = new URLSearchParams(window.location.search);
    if (url.has("paymentSuccess")) {
      if (url.get("paymentSuccess") === "true") {
        setOpenPaymentSuccess(true);
      }
    }
  }, []);

  useEffect(() => {
    checkMonthlyUsage();
  }, []);

  useEffect(() => {
    if (!userData || !userData.dataRequests) return;

    const extractedData = extractRows(userData.dataRequests);
    if (extractedData.length === 0) {
      setFirstTime(true);
    }

    setAllRowData(extractedData);
  }, [userData]);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseLinkModal = () => {
    setLinkModalOpen(false);
  };

  const handleCloseUpgradeAccountModal = () => {
    setUpgradeAccountModalOpen(false);
  };

  const handleRowClick = (row) => {
    navigate(`/bills-data/${row.requestId}`);
  };

  const handleDeleteRow = (key, status) => {
    setDeleteKey(key);
    setDeleteStatus(status);
    setDeleteModalOpen(true);
  };

  return (
    <LoginWrapper>
      <Page currentPage={"requests"}>
        <Container>
          <Header>
            <div>
              <HeaderTitle>Energy Bills</HeaderTitle>
              <HeaderSubtitle>
                Extract data from energy and water bills.
              </HeaderSubtitle>
            </div>
            <HeaderActions>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "8px",
                }}
                onClick={() => {
                  refreshUserData();
                }}
              >
                <RefreshButton icon={Plus} loading={loading} />
              </div>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "8px",
                }}
                onClick={() => {
                  setLinkModalOpen(true);
                }}
              >
                <LinkButton />
              </div>
              <div
                style={{ height: "30px" }}
                onClick={() => {
                  checkMonthlyUsage();
                  if (needsToUpgrade) {
                    setUpgradeAccountModalOpen(true);
                  } else {
                    navigate("/upload-energy-bills");
                  }
                }}
              >
                <CircularButton
                  icon={Plus}
                  type="secondary"
                  title={"Add Data"}
                  content={"Upload Bills"}
                />
              </div>
            </HeaderActions>
          </Header>

          {!firstTime ? (
            <DataGrid
              rowData={allRowData}
              onRowClick={handleRowClick}
              onDeleteRow={handleDeleteRow}
            />
          ) : (
            <Card width={"100%"}>
              <EmptyStateContainer>
                <EmptyStateImg src={Collaboration} />
                <EmptyStateTextContainer>
                  <EmptyStateTextHeader>
                    You don't have any utility bills yet
                  </EmptyStateTextHeader>
                  <div style={{ marginTop: "4px" }} />
                  <EmptyStateText>
                    Upload some energy or water bill files to get started
                  </EmptyStateText>

                  <div
                    style={{ marginTop: "16px" }}
                    onClick={() => {
                      checkMonthlyUsage();
                      if (needsToUpgrade) {
                        setUpgradeAccountModalOpen(true);
                      } else {
                        navigate("/upload-energy-bills");
                      }
                    }}
                  >
                    <SquareButton text="Upload Bills" />
                  </div>
                </EmptyStateTextContainer>
              </EmptyStateContainer>
            </Card>
          )}
        </Container>

        <UpgradeAccountModal
          open={upgradeAccountModalOpen}
          handleClose={handleCloseUpgradeAccountModal}
        />
        <DeleteModal
          open={deleteModalOpen}
          handleClose={handleCloseDeleteModal}
          deleteKey={deleteKey}
          status={deleteStatus}
        />
        <LinkModal open={linkModalOpen} handleClose={handleCloseLinkModal} />
      </Page>
      <PaymentSuccessModal
        open={openPaymentSuccess}
        handleClose={handleClosePaymentSuccess}
      />
    </LoginWrapper>
  );
};

export default DataRequests;
