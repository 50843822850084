import styled from "styled-components";

export const BackButtonContainer = styled.div`
  width: 20px;
  margin-bottom: 30px;
`;

export const BackArrowImg = styled.img`
  height: 12px;
  width: 12px;
  margin-top: 1px;
  margin-right: 8px;
  &:hover {
    animation-name: bounce;
    animation-timing-function: ease;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const BackButtonText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: -1px;
`;

export const BackButton = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;

  ${BackButtonText} {
    color: black;
  }

  ${BackArrowImg} {
    filter: brightness(1) invert(0);
  }
`;
