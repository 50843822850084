import styled from "styled-components";
import { black } from "../../utils/colors";

export const NavContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  min-width: 900px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: right;
`;

export const PageContainer = styled.div`
  width: Calc(100vw - 220px);
  min-width: 924px;
  background-color: #fdfdfd;
`;

export const StyledTableContainer = styled.div`
  width: 950px;
  // height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 8px;
  width: 132px;
`;

export const HeaderTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HeaderSubtitle = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const TableContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  width: 510px;
  margin: 0 auto;
  gap: 55px;
  // margin-top: 100px;
  padding: 20px 50px;
  gap: 66px;
`;

export const EmptyStateImg = styled.img`
  height: 92px;
  // opacity: 0.85;
`;

export const EmptyStateText = styled.div`
  color: #808080;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const EmptyStateTextHeader = styled.div`
  color: ${black};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const EmptyStateTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
