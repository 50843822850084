import React, { useState } from "react";
import { useAuth } from "../../../../../store/AuthContext.js";
import { SquareButton } from "../../../../../components/buttons/SquareButton.js";
import { sendEmailVerification } from "firebase/auth";
import Modal from "../../../../../components/Modal.js";
import styled from "styled-components";

export const TabHeaderTitle = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TabHeaderSubtitle = styled.div`
text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  line-height: 1.5;
  width: 500px;
`;

function VerifyEmailModal({ isEmailVerified, open, handleClose }) {
  const { currentUser } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(" ");

  const handleSendVerificationEmail = async () => {
    setLoading(true);
    try {
      await sendEmailVerification(currentUser);
      setEmailSent(true);
      setLoading(false);
      setMessage(
        "Verification email sent. Please allow a few minutes and refresh the page once complete."
      );
    } catch (error) {
      setMessage("Error sending verification email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const modalContent = () => {
    return (
        <div style={{fontFamily: "Plus Jakarta Sans"}}>
        <div style={{ marginTop: "14px", textAlign: 'center', marginBottom: 16 }}>
          <TabHeaderTitle>Verify Email Address</TabHeaderTitle>
          <TabHeaderSubtitle>
            {isEmailVerified
              ? "Your email address has been verified successfully."
              : "Your email is not yet verified. Verify your email to secure your account."}
          </TabHeaderSubtitle>
        </div>

        <div
          onClick={handleSendVerificationEmail}
          style={{
            width: 162,
            display: isEmailVerified ? "none" : "",
            margin: '0 auto',
          }}
        >
          <SquareButton
            isloading={loading}
            text={"Send Verification Email"}
            disabled={emailSent}
          />
        </div>
        <TabHeaderSubtitle>{message}</TabHeaderSubtitle>
      </div>
    );
  };

  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
}

export default VerifyEmailModal;
