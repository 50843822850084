import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import styled from "styled-components";
import "@fontsource/plus-jakarta-sans";

const Container = styled.div`
  font-family: "Plus Jakarta Sans";
`;

const LoginWrapper = ({ children }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  return <Container>{currentUser ? children : null}</Container>;
};

export default LoginWrapper;
