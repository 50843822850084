import styled from "styled-components";

export const HeaderTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HeaderSubtitle = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const Container = styled.div`
    width: 900px;
`