import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../store/AuthContext";
import getUsageData from "../../apiCalls/getUsagedata";
import UsageBarChart from "../../../api/components/apiKeysTable/usageBarChart";
import { HeaderTitle, HeaderSubtitle, Container } from "./styles";
import LoadingState from "../../../billsUploadResults/pages/loadingState";

const Usage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usage, setUsage] = useState(null);
  const { currentUser } = useAuth();

  async function handleGetUsage() {
    const usageData = await getUsageData(currentUser);
    console.log(usageData);
    setUsage(usageData);
    setIsLoading(false);
  }

  useEffect(() => {
    handleGetUsage();
  }, []);

  return (
    <>
      {isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : (
        <Container>
          <div style={{ marginTop: 40 }}>
            <HeaderTitle>Platform Usage</HeaderTitle>
            <HeaderSubtitle>
              View your past usage of the Upward Platform.
            </HeaderSubtitle>
          </div>
          <UsageBarChart monthlyData={usage.platform} />

          <div style={{ marginTop: 40 }}>
            <HeaderTitle>API Usage</HeaderTitle>
            <HeaderSubtitle>
              View your past usage of the Upward API.
            </HeaderSubtitle>
          </div>
          <UsageBarChart monthlyData={usage.api} />
          <div style={{height: 160}} />
        </Container>
      )}
    </>
  );
};

export default Usage;
