import React from "react";
import LoadingState from "../pages/billsUploadResults/pages/loadingState.js";
import styled from "styled-components";

const Backdrop = styled.div`
  background-color: rgba(265, 265, 265, 0.75);
  // background-color: white;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 50;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 25%;
  left: 5%;
`;

const PageLoading = () => {
  return (
    <Backdrop>
      <LoadingContainer>
        <LoadingState />
      </LoadingContainer>
    </Backdrop>
  );
};

export default PageLoading;
