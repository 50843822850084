import React, { useState, useEffect } from "react";
import { Container, HeaderTitle } from "./styles";
import Page from "../../components/page/index";
import LoginWrapper from "../../store/LoginWrapper";
import { useAuth } from "../../store/AuthContext";
import { SolarSettings } from "./pages/solarProductivity";

const Settings = () => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { currentUser } = useAuth();
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (target) => {
    setCurrentTab(target);
  };

  // Check email verification status on component mount
  useEffect(() => {
    if (currentUser) {
      setIsEmailVerified(currentUser.emailVerified);
    }
  }, [currentUser]);

  return (
    <LoginWrapper>
      <Page currentPage={"settings"}>
        <Container width={"900px"}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <HeaderTitle>Settings</HeaderTitle>
          </div>
          <SolarSettings />
        </Container>
      </Page>
    </LoginWrapper>
  );
};

export default Settings;
