import React from "react";
import styled from "styled-components";

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
  width: 160px;
  height: 32px;
  cursor: pointer;
  transition: 0.3s;
`;

const Option = styled.div`
  width: 80px;
  height: 32px;
  text-align: center;
  font-size: 14px;
  padding-top: 6px;
  color: ${(props) => props.selected ? "#3958ff" : ""};
  border: ${(props) => (props.selected ? "1px solid #3958ff" : "1px solid #ebebeb")};
  background-color: ${(props) => props.selected ? "#f3f6ff" : ""};
  &:hover {
    background-color: ${(props) => (!props.selected ? "#f3f6ff" : "")};
    color: #3958ff;
  }
`

export const Toggle = ({
  options,
  value,
  handleToggle
}) => {
  return (
    <ToggleContainer>
        <Option style={{ borderRadius: "5px 0 0 5px"}} selected={!value} onClick={() => {handleToggle(false)}}>{options[0]}</Option>
        <Option style={{ borderRadius: "0 5px 5px 0"}} selected={value} onClick={() => {handleToggle(true)}}>{options[1]}</Option>
    </ToggleContainer>
  );
};
