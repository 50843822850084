import styled from "styled-components";
import { blue, light_red, dark_red } from "../../../../utils/colors";
import "@fontsource/plus-jakarta-sans";
// Card,
//   CardsContainer,
//   CardTitle,
//   Light36,
//   FlexRow,
//   SemiBold16,
//   RightArrow,
//   FlexRowContainer,

export const CardsContainer = styled.div`

  margin: 0 auto;
  margin-top: 24px;
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const CardsFlex = styled.div`
  display: flex;
  flex-wrap: wrap;  /* Allow wrapping to the next row */
  gap: 16px;
  max-width: 1112px;
  margin: 0 auto;
  padding-bottom: 120px;
`

export const Card = styled.div`
  box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
    0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
  padding: 24px;
  width: 360px;
  height: 405px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-radius: 5px;
`;

export const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const CTA = styled.div`
  color: ${blue};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export const Regular16 = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

export const Light36 = styled.div`
  font-size: 32px;
  font-weight: 200;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Medium16 = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const Light16 = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const FlexColGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  gap: 32px;
`;

export const SemiBold16 = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const SemiBold20 = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const Red = styled.div`
  //   color: ${dark_red};
  color: #fa0e0e;
`;

export const RightArrow = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:hover {
    animation-name: bounce;
    animation-timing-function: ease;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const FlexRowContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const PlusIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SeatsLeft = styled.div`
  padding-left: 36px;
  padding-top: 36px;
`;

export const StaticCheck = styled.img`
width: 18px;
height: 18px;
`;

export const Error = styled.div`
  margin-left: 10px;
  margin-top: -4px;
  font-size: 13px;
  color: ${dark_red}
`