import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const deleteSeat = async (email, idToken) => {
  try {
    const data = await axios({
      method: "POST",
      url: `${expressHost}/auth/deleteUser`,
      data: {
        email: email,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (error) {
    throw error;
  }
};
