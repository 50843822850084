import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { SquareButton } from "../../../components//buttons/SquareButton";
import { generateAPIKey } from "../apiCalls/generateAPIKey";
import styled from "styled-components";
import Input from "../../../components/Input";
import { useAuth } from "../../../store/AuthContext";

const ContentContainer = styled.div`
  font-family: Plus Jakarta Sans;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
`;

const ContentDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  width: 500px;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const APIKeyContainer = styled.div`
  padding: 15px 15px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  box-sizing: border-box;
  height: max-content;
`;
const PrimaryButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const APIKeyModal = ({ open, handleClose, refreshData }) => {
  const { currentUser } = useAuth();
  const [page, setPage] = useState(0);
  const [name, setName] = useState(null);
  const [k, setKey] = useState(null);
  const handleNameChange = (value) => {
    setName(value);
  };
  const [buttonText, setButtonText] = useState("Copy");

  const handleClickCopy = () => {
    navigator.clipboard.writeText(k);
    setButtonText("Copied!");
  };

  const close = () => {
    handleClose();
    setPage(0);
    setKey(null);
  };
  const generateKey = async () => {
    try {
      const data = await generateAPIKey(name, await currentUser.getIdToken());
      setKey(data.data.data);
      setPage(1);
    } catch (e) {
      console.log("error getting key", e);
    }
  };

  const modalHeader0 = () => {
    return <div style={{fontFamily: 'Plus Jakarta Sans'}}>Generate API Key</div>;
  };
  
  const modalHeader1 = () => {
    return <div style={{fontFamily: 'Plus Jakarta Sans'}}>API key successfully created</div>;
  };

  const modalContent0 = () => {
    return (
      <ContentContainer>
        <ContentDescription>
          To connect securely to Upward, your application or tool needs an API
          key with the appropriate permissions.
        </ContentDescription>
        <Input
          title="Descriptive name"
          value={name}
          setChangeInParent={handleNameChange}
        />
        {/* <Radiogroup
                label="What permissions do you want your application to have?"
                options={["Read and write", "Read only", "Write only"]}
                setInParent={setPermission}
              /> */}
      </ContentContainer>
    );
  };

  const modalContent1 = () => {
    return (
      <ContentContainer>
        <APIKeyContainer>{k}</APIKeyContainer>
      </ContentContainer>
    );
  };

  const modalFooter0 = () => {
    return (
      <ButtonContainer onClick={generateKey}>
        <SquareButton text="Generate Key" />
      </ButtonContainer>
    );
  };
  const modalFooter1 = () => {
    return (
      <PrimaryButtonContainer onClick={handleClickCopy}>
        <SquareButton text={buttonText} />
      </PrimaryButtonContainer>
    );
  };
  return (
    <Modal
      open={open}
      handleClose={close}
      modalHeader={page === 0 ? modalHeader0() : modalHeader1()}
      modalContent={page === 0 ? modalContent0() : modalContent1()}
      modalFooter={page === 0 ? modalFooter0() : modalFooter1()}
    />
  );
};

export default APIKeyModal;
