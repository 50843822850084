import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormButton from "../../../components//buttons/FormButton";
import { AuthContentContainer } from "../../../components/styled/AuthContentContainer";
import {
  signInWithEmailAndPassword,
  getMultiFactorResolver,
} from "firebase/auth";
import { auth } from "../../../store/firebase";
import MultiFactorAuth from "./MultifactorLogin";
import styled from "styled-components";
import { dark_blue } from "../../../utils/colors";

const SignUpLoginText = styled.div`
  font-size: 12px;
`;

const SignUpLoginSpan = styled.span`
  color: #3958ff;
  cursor: pointer;
  font-weight: 500;
  transition: 0.03s;
  &:hover {
    color: ${dark_blue};
    text-decoration: underline;
  }
`;

const Login = ({ setLoginState }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [multiFactorResolver, setMultiFactorResolver] = useState(null);
  const [seed, setSeed] = useState(1);

  const reloadComponent = () => {
    setSeed(Math.random());
  };

  const handleEnter = async () => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      navigate("/requests");
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        setLoading(false);
        var resolver = getMultiFactorResolver(auth, error);
        setMultiFactorResolver(resolver);
      } else {
        if (
          error.message.includes("(auth/wrong-password)") ||
          error.message.includes("auth/network-request-failed")
        ) {
          setErrorMessage("Invalid email or password. Try again.");
        } else if (error.message.includes("(auth/too-many-requests)")) {
          setErrorMessage("Too many attempts. Please try again later.");
        }

        setErrors({
          ...errors,
          password: true,
        });
        setLoading(false);
      }
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      {multiFactorResolver ? (
        <MultiFactorAuth
          key={seed}
          reload={reloadComponent}
          resolver={multiFactorResolver}
        />
      ) : (
        <>
          <SignUpLoginText>
            <div>
              No Account?{" "}
              <SignUpLoginSpan
                onClick={() => {
                  setLoginState(false);
                }}
              >
                Sign up now.
              </SignUpLoginSpan>
            </div>
          </SignUpLoginText>
          <AuthContentContainer>
            <TextField
              fullWidth
              id="email"
              label="Email"
              placeholder="johndoe@mail.com"
              inputProps={{
                style: { fontSize: 14, height: 14 },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={handleEmailChange}
            />
            <TextField
              error={errors.password}
              helperText={errors.password && errorMessage}
              fullWidth
              id="password"
              label="Password"
              type="password"
              placeholder="••••••••"
              inputProps={{
                style: { fontSize: 14, height: 14 },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={handlePasswordChange}
            />
          </AuthContentContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <FormButton
              handleClick={handleEnter}
              isloading={loading}
              content={"Log In"}
              width={"84px"}
              height={"28px"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Login;
