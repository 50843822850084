import React from "react";
import Modal from "../../Modal";
import PaymentSuccess from "../../../pages/paymentSuccess/index.js";

const PaymentSuccessModal = ({ open, handleClose }) => {
  const modalContent = () => {
    return <PaymentSuccess handleClose={handleClose} />;
  };
  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default PaymentSuccessModal;
