import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export default async function getStripePortalUrl(currentUser) {
  try {
    const idtoken = await currentUser.getIdToken();

    // Make a POST request to the endpoint
    const res = await axios(`${expressHost}/payments/createPortalSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        idtoken: idtoken,
      },
    });
    window.location.href = res.data.url;
  } catch (error) {
    console.error("Error during checkout:", error);
  }
};