import styled from "styled-components";

export const Regular12 = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const Regular14 = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Regular16 = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const Regular18 = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

export const Regular20 = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

export const Light36 = styled.div`
  font-size: 36px;
  font-weight: 200;
`;

export const Medium12 = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const Medium16 = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const Medium14 = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const Light16 = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

export const SemiBold14 = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const SemiBold16 = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const SemiBold20 = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const Bold18 = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
