import React from "react";
import {
  BackButton,
  BackArrowImg,
  BackButtonText,
  BackButtonContainer,
} from "./styled.js";
import BlackBackArrow from "../../../assets/BlackBackArrow.svg";

const AnimatedBackButton = ({ action }) => {
  return (
    <BackButtonContainer onClick={action}>
      <BackButton>
        <BackArrowImg src={BlackBackArrow} />
        <BackButtonText>Back</BackButtonText>
      </BackButton>
    </BackButtonContainer>
  );
};

export default AnimatedBackButton;
