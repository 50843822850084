import React, { useState, useEffect } from "react";
import {
  CardsContainer,
  Card,
  FlexRow,
  Red,
  PlusIcon,
  SeatsLeft,
  Regular16Link,
  SeatActionsContainer,
  SeatRow,
  SeatName,
} from "./styles.js";
import {
  Regular20,
  Regular16,
  Medium16,
  SemiBold16,
  Regular14,
} from "../../../../components/styled/fonts.js";
import { blue } from "../../../../utils/colors.js";
import Plus from "../../../../assets/BlackPlus.svg";
import CreateSeatModal from "../../../../components/modals/CreateSeatModal/index.js";
import { createSeat } from "./../../apiCalls/createSeat.js";
import { useGlobalData } from "../../../../store/GlobalDataProvider.js";
import { deleteSeat } from "./../../apiCalls/deleteSeat.js";
import { useAuth } from "../../../../store/AuthContext.js";
import { makeAdmin } from "./../../apiCalls/makeAdmin.js";
import { removeAdmin } from "./../../apiCalls/removeAdmin.js";
import PageLoading from "../../../../components/PageLoading.js";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const Seats = () => {
  const { currentUser } = useAuth();
  const { userData, loading } = useGlobalData();
  const navigate = useNavigate();
  const [createSeatModalOpen, setCreateSeatModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseCreateSeatModal = () => {
    setCreateSeatModalOpen(false);
  };
  const onlyOneAdmin = () => {
    var adminCount = 0;
    for (let i = 0; i < userData.users.length; i++) {
      if (userData.users[i].admin === true) {
        adminCount++;
      }
    }
    if (adminCount === 1) {
      return true;
    } else if (adminCount > 1) {
      return false;
    }
  };

  const removeAsAdmin = async (seat) => {
    setIsLoading(true);
    try {
      await removeAdmin(seat.email, await currentUser.getIdToken());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("failed");
    }
  };

  const deleteUser = async (seat) => {
    setIsLoading(true);
    try {
      await deleteSeat(seat.email, await currentUser.getIdToken());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("failed");
    }
  };

  const makeAnAdmin = async (seat) => {
    setIsLoading(true);
    try {
      await makeAdmin(seat.email, await currentUser.getIdToken());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("failed");
    }
  };

  return (
    <>
      {/* <SeatsLeft>
        <Regular16>{`You have ${
          userData.payments.subscription.maxSeats - userData.users.length
        } ${
          userData.payments.subscription.maxSeats - userData.users.length !== 1
            ? "seats"
            : "seat"
        }  remaining.`}</Regular16>
      </SeatsLeft> */}
      <div style={{ marginTop: "24px" }}>
        <Card>
          <Regular20>Users</Regular20>
          <Regular14>{`You have ${
            userData.payments.subscription.maxSeats - userData.users.length
          } ${
            userData.payments.subscription.maxSeats - userData.users.length !==
            1
              ? "seats"
              : "seat"
          }  remaining.`}</Regular14>
          {userData &&
            userData.users &&
            userData.users.map((seat, i) => {
              return (
                <SeatRow
                  borderBottom={
                    i < userData.users.length - 1 ||
                    userData.payments.subscription.maxSeats -
                      userData.users.length >
                      0
                  }
                >
                  <SeatName>
                    {seat.admin && (
                      <>
                        <Regular16>{`${seat.email} - `}</Regular16>
                        <SemiBold16>Admin</SemiBold16>
                      </>
                    )}
                    {!seat.admin && <Regular16>{seat.email}</Regular16>}
                  </SeatName>
                  <SeatActionsContainer>
                    {seat.admin &&
                      userData.users.length > 1 &&
                      !onlyOneAdmin() &&
                      currentUser.email !== seat.email && (
                        <div
                          style={{ color: blue, cursor: "pointer" }}
                          onClick={() => removeAsAdmin(seat)}
                        >
                          <Regular16>Remove as admin</Regular16>
                        </div>
                      )}
                    {!seat.admin && (
                      <>
                        <div
                          style={{ color: blue, cursor: "pointer" }}
                          onClick={() => makeAnAdmin(seat)}
                        >
                          <Regular16>Make admin</Regular16>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteUser(seat)}
                        >
                          <Regular16>
                            <Red>Delete User</Red>
                          </Regular16>
                        </div>
                      </>
                    )}
                  </SeatActionsContainer>
                </SeatRow>
              );
            })}
          {userData.payments.subscription.maxSeats - userData.users.length >
            0 && (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setCreateSeatModalOpen(true)}
            >
              <PlusIcon src={Plus} />
              <Regular16>Add User</Regular16>
            </div>
          )}
        </Card>
      </div>

      {/* <CardsContainer>
        {userData &&
          userData.users &&
          userData.users.map((seat) => {
            return (
              <Card>
                {seat.admin && (
                  <>
                    <FlexRow>
                      <Regular20>{seat.email}</Regular20>
                      <Regular16>Admin</Regular16>
                    </FlexRow>

                    {userData.users.length > 1 &&
                      !onlyOneAdmin() &&
                      currentUser.email !== seat.email && (
                        <div
                          style={{ color: blue, cursor: "pointer" }}
                          onClick={() => removeAsAdmin(seat)}
                        >
                          <Regular16>Remove as admin</Regular16>
                        </div>
                      )}
                  </>
                )}
                {!seat.admin && (
                  <>
                    <Regular20>{seat.email}</Regular20>
                    <FlexRow>
                      <div
                        style={{ color: blue, cursor: "pointer" }}
                        onClick={() => makeAnAdmin(seat)}
                      >
                        <Regular16>Make admin</Regular16>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteUser(seat)}
                      >
                        <Regular16>
                          <Red>Delete User</Red>
                        </Regular16>
                      </div>
                    </FlexRow>
                  </>
                )}
              </Card>
            );
          })}
        {userData.payments.subscription.maxSeats - userData.users.length >
          0 && (
          <Card>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setCreateSeatModalOpen(true)}
            >
              <PlusIcon src={Plus} />
              <Regular20>Add Profile</Regular20>
            </div>
          </Card>
        )}
      </CardsContainer> */}
      {userData.payments.subscription.maxSeats - userData.users.length ===
        0 && (
        <SeatsLeft>
          <Regular16>Need more seats?</Regular16>{" "}
          <Regular16Link onClick={() => navigate("/plans")}>
            Upgrade your subscription
          </Regular16Link>{" "}
          <Regular16>or contact us at support@buildupward.com</Regular16>
        </SeatsLeft>
      )}
      <CreateSeatModal
        open={createSeatModalOpen}
        handleClose={handleCloseCreateSeatModal}
        submit={createSeat}
      />
      {(isLoading || loading) && <PageLoading />}
    </>
  );
};

export default Seats;
