import React, { useState } from "react";
import Modal from "../../Modal.js";
import { ActionsContainer, InputsContainer } from "./styles.js";
import { SquareButton } from "../../buttons/SquareButton.js";
import Input from "../../Input";
import { useAuth } from "../../../store/AuthContext.js";
import { createSeat } from "../../../pages/account/apiCalls/createSeat.js";
import { useSnackbar } from "notistack";
import { buttons, snackbarStyles } from "../../snackbars/MessageBar";

const CreateSeatModal = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  const handleNameChange = (value) => {
    setName(value);
  };
  const handleEmailChange = (value) => {
    setEmail(value);
  };
  const modalHeader = () => {
    return <div>Create New Seat</div>;
  };

  const createNewSeat = async () => {
    try {
      await createSeat(email, name, await currentUser.getIdToken());
      handleClose();
    } catch (error) {
      enqueueSnackbar(buttons.errorDuplicateUserForSeat, {
        style: snackbarStyles.error,
      });
      console.log("error creating seat");
    }
  };

  const modalFooter = () => {
    return (
      <ActionsContainer>
        <div onClick={createNewSeat}>
          <SquareButton text={"Create Seat"} />
        </div>
      </ActionsContainer>
    );
  };
  const modalContent = () => {
    return (
      <InputsContainer>
        <Input title={"Name"} setChangeInParent={handleNameChange} />
        <Input title={"Email"} setChangeInParent={handleEmailChange} />
      </InputsContainer>
    );
  };

  return (
    <Modal
      modalHeader={modalHeader()}
      modalFooter={modalFooter()}
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default CreateSeatModal;
