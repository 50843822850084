import React from "react";
import styled from "styled-components";
import Modal from "../../Modal.js";
import greenCheck from "../../../assets/greenCheck.svg";
import "@fontsource/plus-jakarta-sans";

const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Plus Jakarta Sans";
`;

const SuccessText = styled.div`
  margin-top: 32px;
  font-size: 26px;
  font-weight: 500;
`;

const Text = styled.div`
  color: #000000;
  font-size: 13px;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Check = styled.img`
  width: 48px;
  height: 48px;
  margin: 0 auto;
`;

const SuccessModal = ({ open, handleClose, text, icon }) => {
  const modalContent = () => {
    return (
      <Container>
        <Check src={icon} />
        <SuccessText>Success</SuccessText>
        <Text>{text}</Text>
      </Container>
    );
  };

  return (
    <Modal
      modalContent={modalContent()}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default SuccessModal;
