import styled from "styled-components";
import { blue } from "../../utils/colors.js";

export const Container = styled.div`
  width: ${(props) => props.width};
  margin: 48px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const PageContainer = styled.div`
  width: Calc(100vw - 220px);
  min-width: 974px;
  background-color: #fdfdfd;
  position: relative;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.18);
  width: 100%;
  align-items: center;
  gap: 35px;
  padding: 30px 0;
`;

export const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.08);
  padding: 36px;
  max-width: 458px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 400px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 472px;
`;

export const SecondaryActionButton = styled.div`
  border-radius: 5px;
  padding: 10px 35px;
  border: 2px solid ${blue};
  color: ${blue};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
`;

export const PrimaryActionButton = styled.div`
  border-radius: 5px;
  padding: 10px 35px;
  border: 2px solid ${blue};
  color: #ffffff;
  background-color: ${blue};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
`;
