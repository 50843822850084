import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { getUserData } from './apiCalls/getUserData';
import { useAuth } from './AuthContext';
import { isEqual } from "lodash";
import { expressHost } from '../utils/hosts';
import { getCompanyUploadLink } from './apiCalls/getCompanyUploadLink';

const GlobalDataContext = createContext();

export const GlobalDataProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ws, setWs] = useState(null);
  const [uploadLink, setUploadLink] = useState(null);
  const RECONNECT_INTERVAL = 5000;

  const fetchUserData = useCallback(async (idToken) => {
    setLoading(true); // Set loading to true when data refresh starts
    try {
      const response = await getUserData(idToken);
      // Update state only if data has genuinely changed
      if (!isEqual(response.data.data, userData)) {
        setUserData(response.data.data);
      }

      if (response.data.data.shareLink !== uploadLink) {
        setUploadLink(response.data.data.shareLink)
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false); // Set loading to false when data refresh completes
    }
  }, [userData]);

  useEffect(() => {

    if (currentUser && !ws) {
      let retryTimeout;
      let pingInterval;

      const initializeWebSocket = async () => {
        const socket = new WebSocket(expressHost); // Update URL if needed

        socket.onopen = async () => {
          console.log('WebSocket connection established');
          const token = await currentUser.getIdToken();
          socket.send(JSON.stringify({ token }));

          // Start pinging the server every 30 seconds
          pingInterval = setInterval(() => {
            if (socket.readyState === WebSocket.OPEN) {
              socket.send(JSON.stringify({ type: 'ping' }));
            }
          }, 45000); // ping every 45 seconds
        };

        socket.onmessage = async (event) => {
          const message = JSON.parse(event.data);
          if (message.type === 'UPDATE') {
            await refreshUserData();
          }
        };

        socket.onclose = () => {
          console.log('WebSocket connection closed');
          clearInterval(pingInterval); // Clear ping interval
          retryConnection(); // Retry connection on close
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          clearInterval(pingInterval); // Clear ping interval
          retryConnection(); // Retry connection on error
        };

        setWs(socket)
      };
      
      const fetchInitialDataAndInitializeWebSocket = async () => {
        const token = await currentUser.getIdToken();
        await fetchUserData(token);
        initializeWebSocket();
      };

      const retryConnection = () => {
        clearTimeout(retryTimeout);
        // Attempt to reconnect after a specified interval
        retryTimeout = setTimeout(() => {
          console.log('Attempting to reconnect to WebSocket...');
          initializeWebSocket();
        }, RECONNECT_INTERVAL);
      };

      fetchInitialDataAndInitializeWebSocket();

      // Cleanup WebSocket connection on component unmount
      return () => {
        if (ws) {
          ws.close();
        }
        clearTimeout(retryTimeout);
      };
    }
  }, [currentUser]);

  const refreshUserData = async () => {
    if (currentUser) {
      const idToken = await currentUser.getIdToken();
      await fetchUserData(idToken);
    }
  };

  const clearUserData = async () => {
    setUserData(null)
  }

  // run intial refresh
  useEffect(() => {
      refreshUserData()
  }, [currentUser])

  return (
    <GlobalDataContext.Provider value={{ userData, loading, refreshUserData, uploadLink, clearUserData }}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => useContext(GlobalDataContext);