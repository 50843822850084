import React from "react";
import Page from "../../components/page/index";
import LoginWrapper from "../../store/LoginWrapper";
import {
  PageContainer,
  FlexContainer,
  Card,
  Separator,
  FlexRow,
  ActionsContainer,
  SecondaryActionButton,
  PrimaryActionButton,
} from "./styles.js";
import {
  SemiBold20,
  Bold18,
  Regular12,
  Medium12,
  Regular16,
} from "../../components/styled/fonts.js";
import { LightGrey } from "../../components/styled/textColors.js";
import { useNavigate } from "react-router-dom";

const ReviewPlanChange = () => {
  const navigate = useNavigate();
  const navigateToPlans = () => {
    navigate("/plans");
  };
  return (
    <LoginWrapper>
      <Page>
        <PageContainer>
          <FlexContainer>
            <Card>
              <SemiBold20>Subscription Changes</SemiBold20>
              <Separator />
              <LightGrey>
                <Medium12>YOU WANT TO SWITCH TO</Medium12>
              </LightGrey>
              <Bold18>Pro</Bold18>
            </Card>
            <Card>
              <SemiBold20>Your Subscription</SemiBold20>
              <FlexRow>
                <Regular16>Pro</Regular16>
                <Regular16>$500.00 / month</Regular16>
              </FlexRow>
            </Card>
            <Card>
              <FlexRow>
                <SemiBold20>Total Due Today</SemiBold20>
                <SemiBold20>$300.00</SemiBold20>
              </FlexRow>
            </Card>
            <Card>
              <SemiBold20>Upcoming Charges</SemiBold20>
              <FlexRow>
                <SemiBold20>Date</SemiBold20>
                <SemiBold20>Amount</SemiBold20>
              </FlexRow>
              <Separator />
              <FlexRow>
                <Regular16>09/24/24</Regular16>
                <Regular16>$500.00</Regular16>
              </FlexRow>
              <Separator />
              <FlexRow>
                <Regular16>09/24/24</Regular16>
                <Regular16>$500.00</Regular16>
              </FlexRow>
              <Separator />
              <FlexRow>
                <Regular16>09/24/24</Regular16>
                <Regular16>$500.00</Regular16>
              </FlexRow>
              <Separator />
              <LightGrey>
                <Regular12>
                  For further upcoming charges visit your Account page.
                </Regular12>
              </LightGrey>
            </Card>
            <ActionsContainer>
              <SecondaryActionButton onClick={() => navigateToPlans()}>
                Back
              </SecondaryActionButton>
              <PrimaryActionButton>Submit</PrimaryActionButton>
            </ActionsContainer>
          </FlexContainer>
        </PageContainer>
      </Page>
    </LoginWrapper>
  );
};

export default ReviewPlanChange;
